import { ShareRequestStatuses } from "@/components/constants.js"
import { ShareRequestStatus } from "@evercam/shared/types/shares"

export default {
  shareeEmail: {
    component: "TextFieldSearchFilter",
  },
  camera: {
    component: "TextFieldSearchFilter",
  },
  sharer: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      value: ShareRequestStatus.Pending,
      items: [{ value: "", name: "All" }, ...ShareRequestStatuses],
    },
  },
}
